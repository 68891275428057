import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../../images/empty_office.jpeg";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div
        className="d-flex flex-column align-items-center justify-content-center text-white"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "80vh",
          position: "relative",
          textAlign: "center",
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <h1
          className="display-4"
          style={{
            textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
            fontWeight: "bold",
            paddingTop: "10rem",
          }}
        >
          CENTRE FRANCOPHONE DE DBT
        </h1>
        <div
          className="position-absolute top-0 start-0 end-0 bottom-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        />
        <Container className="position-relative">
          <Row className="justify-content-center">
            <Col lg={12}>
              <div style={{ paddingTop: "20rem" }} />
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="my-5">
        <Row className="mb-5">
          <Col xs={12}>
            <p>
              L'équipe du Centre Francophone de Dialectical Behavioral Therapy
              (DBT ou TCD) est constituée de psychologues, psychiatres et
              thérapeutes animés par l'engagement de proposer aux patients les
              protocoles de soins scientifiquement validés les plus efficaces
              pour répondre à leurs besoins.
            </p>
            <p>
              L'équipe du Centre accueille toute personne, de la petite enfance
              jusqu'à l'âge adulte, souffrant de dysrégulation émotionnelle :
              troubles de la personnalité, troubles de l'humeur, troubles
              anxieux, troubles de conduites alimentaires (TCA), addictions,
              psychotraumatisme. Depuis sa création, en septembre 2019, notre
              équipe a pris en charge 3600 patients, en grande partie en DBT.
            </p>
            <br />
            <br />
            <br />
            <h2 className="text-center mb-4">
              Le Centre Francophone de DBT propose :
            </h2>
            <ul className="list-unstyled">
              <li>
                Une évaluation à visée diagnostique de la situation et des
                besoins de chaque patient et de sa famille.
              </li>
              <li>
                Des protocoles de soin pour répondre concrètement aux problèmes
                <ul>
                  <li>
                    <Link to="/therapy#enfants-adolescents">DBT child</Link> : pour les enfants
                    entre 3 et 11 ans
                  </li>
                  <li>
                    <Link to="/therapy#enfants-adolescents">DBT teens</Link> : pour les adolescents
                    entre 12 et 18 ans
                  </li>
                  <li>
                    <Link to="/therapy#adulte">DBT Standard</Link> : pour les adultes
                  </li>
                  <li>
                    Et des <Link to="/therapy#dbt-standard">protocoles DBT spécifiques</Link> pour des problématiques
                    particulières (psychotraumatisme, addiction, TCA).
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="my-5">
          <Col xs={12}>
            <h2 className="text-center mb-4">Pourquoi choisir la DBT ?</h2>
            <p>
              Le Centre Francophone de DBT a adopté cette approche
              thérapeutique, car la DBT est le protocole de soin
              scientifiquement validé le plus efficace, à ce jour, dans le soin
              de la dysrégulation émotionnelle.
            </p>
            <p>
              Aujourd'hui, des publications scientifiques suggèrent que, dans le
              monde, 136 millions de personnes souffrent d'une grave
              dysrégulation émotionnelle dans leur vie : colère, vide,
              intolérance à la frustration, conduites à risques, passage à
              l'acte, manque de sens ... Cette dysrégulation émotionnelle
              dégrade considérablement la qualité de vie, éloigne ces personnes
              de leurs raisons pour vivre et met parfois en danger leur
              existence.
            </p>
          </Col>
        </Row>
      </Container>

      {/* Chargement paresseux de l'image masquée */}
      <picture>
        <source
          srcSet={`${backgroundImage}?w=800 800w, ${backgroundImage}?w=1200 1200w`}
          type="image/jpeg"
        />
        <img
          src={backgroundImage}
          alt="Bureau vide"
          loading="lazy"
          style={{ display: 'none' }} // Masquer l'image si elle n'est pas nécessaire
        />
      </picture>
    </>
  );
};

export default Home;
